import axios from 'axios'
import Vue from 'vue';
// import store from '../store';
// import {router} from '../router/index';
axios.defaults.withCredentials = true
let vm = new Vue();

const instance = axios.create({
    baseURL: '/zhmy-finance-api',
    // baseURL: 'http://localhost:18080/sw/api/data',
    // baseURL: 'http://localhost:18080/sw/api/data',
    // baseURL: 'http://jg.nmgzhmy.com/zhmy-finance-api/',
    timeout: 30000,
    withCredentials:true,
    headers: {
        post: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    }
})

// 请求拦截
instance.interceptors.request.use(config => {
    // 自定义header，可添加项目token
    //var token = sessionStorage.getItem("token");

    // if (token) {
    //     // config.headers.Cookie = token;
    //     // document.csookie=token;
    //     // config.headers.timestamp = new Date().getTime();
    // }
    return config;
}, error => {
    return Promise.reject(error);
})

// 响应拦截
instance.interceptors.response.use(response => {
    const resCode = response.status;
    // console.log(response.headers)
    if (resCode === 200) {
        const code = response.data.code;
        if (code === 403) {
            vm.$message.error("身份鉴别信息过期，请重新登录！");
            location.href = '/login'
        }
        return Promise.resolve(response);
    } else {
        return Promise.reject(response);
    }
    // return response;
}, error => {
    const resCode = error.response.status;
    if (resCode === 401) {
        vm.$message.error("登录失败，请检查用户名或密码是否填写正确！");
    }
    //const resCode = error.response.status;
    //vm.$Message.error(error.response.data.message);
    // switch (resCode) {
    //   case 401:
    //     vm.$Message.error(error.response.data.message);
    //     store.commit('logout', this);
    //     store.commit('clearOpenedSubmenu');
    //     // console.log('token-0', store.state.app.token);
    //     router.replace({
    //       name: 'login'
    //     });
    //     break;
    //   case 404:
    //     vm.$Message.error('网络请求不存在');
    //     break;
    //   case 500:
    //     vm.$Message.error('服务器连接错误');
    //     break;
    //   // 其他状态码错误提示
    //   default:
    //     vm.$Message.error(error.response.data.message);
    // }
    return Promise.reject(error);
})

/*
 *封装get方法
 *@param{String} url [请求地址]
 *@param{Object} params 请求参数
 */
export function Get(url, params) {
    return new Promise((resolve, reject) => {
        instance.get(url, {
            params: params
        }).then((res) => {
            resolve(res.data);
        }).catch((error) => {
            reject(error.data);
        })
    })
}

/**
 *封装post方法
 *@param{String} url 请求地址
 *@param{Object} params 请求参数
 */
export function Post(url, params) {
    //console.log(url)
    return new Promise((resolve, reject) => {
        instance.post(url, params).then((res) => {
            resolve(res.data);
        }).catch((error) => {
            reject(error.data);
        })
    })
}

/**
 *封装put方法
 *@param{String} url 请求地址
 *@param{Object} params 请求参数
 */
export function Put(url, params) {
    return new Promise((resolve, reject) => {
        instance.put(url, params).then((res) => {
            resolve(res.data);
        }).catch((error) => {
            reject(error.data);
        })
    })
}

/**
 *封装patch方法
 *@param{String} url 请求地址
 *@param{Object} params 请求参数
 */
export function Patch(url, params) {
    return new Promise((resolve, reject) => {
        instance.put(url, params).then((res) => {
            resolve(res.data);
        }).catch((error) => {
            reject(error.data);
        })
    })
}

/**
 *封装delete方法
 *@param{String} url [请求地址]
 *@param{Object} params [请求参数]
 */
export function Delete(url, params) {
    return new Promise((resolve, reject) => {
        instance.delete(url, {
            params: params
        }).then((res) => {
            resolve(res.data);
        }).catch((error) => {
            reject(error.data);
        })
    })
}
