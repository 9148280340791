export default [
    require('../assets/images/admin.png'),
    require('../assets/images/bg01.jpg'),
    require('../assets/images/bg02.png'),
    require('../assets/images/cxBtn.png'),
    require('../assets/images/detail.png'),
    require('../assets/images/detailLb.png'),
    require('../assets/images/head.png'),
    require('../assets/images/jkzt.png'),
    require('../assets/images/k.png'),
    require('../assets/images/lc.png'),
    require('../assets/images/login.jpg'),
    require('../assets/images/loginBtn.png'),
    require('../assets/images/loginForm.png'),
    require('../assets/images/sl.png'),
    require('../assets/images/wd1.png'),
    require('../assets/images/wd2.png'),
    require('../assets/images/wd3.png'),
    require('../assets/images/yd1.png'),
    require('../assets/images/yd2.png'),
    require('../assets/images/yd3.png'),
    require('../assets/images/zltj1.png'),
    require('../assets/images/zltj2.png'),
    require('../assets/images/zltj3.png'),
    require('../assets/images/zltj4.png'),
]