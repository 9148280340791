import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import Api from './api/index'
import { imgsPreloader } from './config/imgPreloader.js';
import imgPreloaderList from './config/imgPreloaderList.js';

import axios from 'axios'
import moment from "moment";

import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)
//需要汉化
moment.locale('zh-cn');
Vue.prototype.$moment = moment;//赋值使用
axios.defaults.withCredentials = true

Vue.use(ElementUI)

Vue.config.productionTip = false

Vue.prototype.$axios = Api;

(async () => {
  await imgsPreloader(imgPreloaderList);
  //关闭加载弹框
  //document.querySelector('.loading').style.display = 'none';
  new Vue({
    router,
    render: h => h(App)
  }).$mount('#app');
})();
